window.addEventListener('load', (event) => {
    document.querySelectorAll(".btn-logout").forEach((elm) => {
        elm.addEventListener('click',(event) => {
            event.preventDefault();
            let form = document.getElementById('logout-form');
            if (form instanceof HTMLFormElement)
                form.submit();
        });
    });

    document.querySelectorAll('#tabs a').forEach(node => {
        let trigger = new bootstrap.Tab(node)
        node.addEventListener('click', e => {
            e.preventDefault()
            trigger.show()
        })
    });
});
